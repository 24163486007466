exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accesos-js": () => import("./../../../src/pages/accesos.js" /* webpackChunkName: "component---src-pages-accesos-js" */),
  "component---src-pages-categorias-js": () => import("./../../../src/pages/categorias.js" /* webpackChunkName: "component---src-pages-categorias-js" */),
  "component---src-pages-contenidos-js": () => import("./../../../src/pages/contenidos.js" /* webpackChunkName: "component---src-pages-contenidos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-juegos-js": () => import("./../../../src/pages/juegos.js" /* webpackChunkName: "component---src-pages-juegos-js" */),
  "component---src-pages-micuenta-js": () => import("./../../../src/pages/micuenta.js" /* webpackChunkName: "component---src-pages-micuenta-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-newscategory-js": () => import("./../../../src/templates/newscategory.js" /* webpackChunkName: "component---src-templates-newscategory-js" */)
}

