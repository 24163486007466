import { createStore as reduxCreateStore } from "redux"
import * as UtilFuncs from "../utils/utils";

const reducer = (state, action) => {
  ////console.log("STORE", state, action);
  let result = state;
  var sidebarStatus = "";
  switch (action.type) {
    case 'INCREMENT':
      //console.log("STORE increment", state);
      result = Object.assign({}, state, {
        count: state.count + 1,
      }) 
      
      break;


      case `DECREMENT`:
        //console.log("STORE decrement", state);
        result = Object.assign({}, state, {
        count: state.count - 1,
      })
    break;

    case  `OPENSIDEBAR`:
        //console.log("STORE open sidebar", state);
        sidebarStatus = "Open";
    UtilFuncs.markAnalyticsEvent(
      {
        status: sidebarStatus,
        event_category: "Sidebar",
        event_label: "Sidebar " + sidebarStatus,
      },
      "SIDEBAR"
    );
        result = Object.assign({}, state, {
        sidebarOpened: true,
      })
      break;
      case  `CLOSESIDEBAR`:
        //console.log("STORE close sidebar", state);
        sidebarStatus = "Close";
    UtilFuncs.markAnalyticsEvent(
      {
        status: sidebarStatus,
        event_category: "Sidebar",
        event_label: "Sidebar " + sidebarStatus,
      },
      "SIDEBAR"
    );
        result = Object.assign({}, state, {
        sidebarOpened: false,
      })
      break;
    case  `TOGGLESIDEBAR`:
        //console.log("STORE toggle sidebar", state);
        sidebarStatus = !state.sidebarOpened == true ? "Open" : "Close";
		UtilFuncs.markAnalyticsEvent(
			{
				status: sidebarStatus,
				event_category: "Sidebar",
				event_label: "Sidebar " + sidebarStatus,
			},
			"SIDEBAR"
		);
        result = Object.assign({}, state, {
        sidebarOpened: !state.sidebarOpened,
      })
      break;
    case  `TOGGLETHEME`:
        //console.log("STORE toggle theme", state);
        result = Object.assign({}, state, {
        preftheme: (state.preftheme === 'dark') ? 'light' : 'dark',
      })
      break;
    
    case `CURRENTLYPLAYING/SETSRC`:
        //console.log("STORE currently playing set src", state, action);
        result = Object.assign({}, state, {
        currentPlayingSrc: ((action.payload !== undefined) ? action.payload.src : ""),
      })
      break;
    
    case `SETVIDEOIFRAMESRC`:
        //console.log("STORE video iframe set src", state, action);
        result = Object.assign({}, state, {
        videoIframeSrc: ((action.payload !== undefined) ? action.payload.src : ""),
      })
      break;
    
  
    default:
      result = state
      break;
  }
  return result;
}

const initialState = { 
  count: 0, 
  sidebarOpened: false, 
  preftheme: 'dark', 
  currentPlayingSrc: '',
  videoIframeSrc: '',
}

const createStore = () => {
  if(process.env.USE_DEBUGTOOLS === "true"){
    if(typeof window == "undefined"){
    return reduxCreateStore(
      reducer, 
      initialState
      )
    }
  return reduxCreateStore(
    reducer, 
    initialState,
    typeof window != undefined &&  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()  
    )
  }
  return reduxCreateStore(
    reducer, 
    initialState
    )
}
export default createStore